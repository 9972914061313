<template>
    <v-card elevation="4">
        <v-toolbar dense flat color="teal white--text">
            <v-toolbar-title>Domain Verification</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <template v-if="isCreateDomainVerificationRequired">
                <p class="mb-0 pb-0">
                    No pending verification requests.
                </p>
                <p class="mb-0 pb-0">
                    <v-btn color="green" class="white--text" @click="createVerificationCode">Start</v-btn>
                </p>
            </template>
            <template v-if="isCheckDomainVerificationRequired">
                <!-- <v-alert dense type="warning" border="left" elevation="2" class="mt-8">
                    There is a pending verification request.
                </v-alert> -->
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    To prove ownership of the domain, you need to create the following DNS record:
                </p>
                <v-simple-table class="mt-8">
                    <template #default>
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Hostname</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><span class="font-weight-bold">TXT</span></td>
                                <td><span class="font-weight-bold">_brandprofile</span><span class="font-weight-light">.{{ domain.name }}</span></td>
                                <td><span class="font-weight-bold">{{ verification.code }}</span></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>

                <!-- TODO: get a relative expiration time from the server, in seconds, then display it here like "code expires in 15 minutes". -->
                <template v-if="verification.not_after">
                <p class="text-overline mb-0 mt-8">Verification code expires</p>
                <p class="mb-0 pb-0">
                    {{ verificationCodeNotAfterDisplay }}
                </p>
                </template>

                <p class="text-overline mb-0 mt-8">Quick links</p>
                <p class="mb-0 pb-0 mt-2">
                    <TextLink :href="libertydnsEditDnsLink" target="_blank">Manage at LibertyDNS</TextLink>. If the link doesn't work, visit <TextLink href="https://libertydns.io" target="_blank">LibertyDNS</TextLink>, login, then search for the domain.
                </p>
                <p class="mb-0 pb-0 mt-2">
                    <TextLink :href="godaddyEditDnsLink" target="_blank">Manage at GoDaddy</TextLink>. If the link doesn't work, visit <TextLink href="https://godaddy.com" target="_blank">GoDaddy</TextLink>, login, then navigate to My Domains -&gt; DNS Management.
                </p>
            </template>
        </v-card-text>
        <!-- <v-card-actions class="pb-4">
            <v-spacer/>
            <v-btn :to="{ name: 'login' }" elevation="4" color="teal white--text">Login</v-btn>
            <v-spacer/>
        </v-card-actions> -->
    </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { toMillis } from '@/sdk/time';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        TextLink,
    },
    props: ['domain', 'verification'],
    data: () => ({
        timer: null,
    }),
    computed: {
        ...mapState({
            focus: (state) => state.focus,
        }),
        isCreateDomainVerificationRequired() {
            return this.domain !== null && this.domain.status !== 'verified' && this.verification === null;
        },
        isCheckDomainVerificationRequired() {
            return this.domain !== null && this.domain.status !== 'verified' && this.verification !== null;
        },
        godaddyEditDnsLink() {
            // TODO: this is brittle, we need to monitor godaddy for changes if we're going to show this, or have a way for users to let us know it is broken
            return this.verification?.domain ? `https://dcc.godaddy.com/manage/${this.verification.domain}/dns/` : '';
        },
        libertydnsEditDnsLink() {
            return this.verification?.domain ? `https://app.libertydns.io/domain/${this.verification.domain}` : '';
        },
        verificationCodeNotAfterDisplay() {
            try {
                if (typeof this.verification?.not_after === 'number' && this.verification.not_after > 0) {
                    const date = new Date(this.verification.not_after);
                    return date.toString();
                }
                return 'Unknown';
            } catch (err) {
                console.log(`failed to parse verification code not-after date: ${this.verification.not_after}`);
                return 'Unknown';
            }
        },
    },
    watch: {
        focus() {
            // when user switches back to this window, reset the timer (if we had one) and immediately check again
            if (this.timer) {
                clearTimeout(this.timer);
                this.timer = setTimeout(() => { this.checkVerification(); }, toMillis({ seconds: 15 }));
            }
        },
    },
    methods: {
        async createVerificationCode() {
            try {
                this.$store.commit('loading', { createVerificationCode: true });
                const response = await this.$client.account(this.$route.params.accountId).domainVerification.create({ domain_id: this.$route.params.domainId });
                console.log(`createVerificationCode: response ${JSON.stringify(response)}`);
                if (response) {
                    this.verification = response;
                    this.timer = setTimeout(() => { this.checkVerification(); }, toMillis({ seconds: 15 }));
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain verification request' });
            } finally {
                this.$store.commit('loading', { createVerificationCode: false });
            }
        },
        async checkVerification() {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { checkVerification: true });
                const response = await this.$client.account(this.$route.params.accountId).domainVerification.check({ domain_id: this.$route.params.domainId });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.verification ??= {};
                    this.$set(this.verification, 'status', response.status);

                    if (response.status === 'verified') {
                        this.$emit('verified');
                    }

                    if (response.status !== 'verified') {
                        console.log('checkVerification: continue auto-check timer');
                        this.timer = setTimeout(() => { this.checkVerification(); }, toMillis({ seconds: 15 }));
                    }
                }
            } catch (err) {
                console.error('failed to check domain verificaiton status', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to check domain verification status' });
            } finally {
                this.$store.commit('loading', { checkVerification: false });
            }
        },
    },
    mounted() {
        if (this.isCheckDomainVerificationRequired) {
            this.checkVerification();
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.timer) {
            console.log('beforeRouteLeave: clearing timer');
            clearTimeout(this.timer);
        }
        next();
    },
};
</script>
