<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row>
        <v-row justify="center" class="py-5 mt-2" v-if="domain">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ domain.name }}</h1>
            <!-- <h1 class="text-caption font-weight-light text-center">{{ domain.status }}</h1> -->
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5 px-10">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <template v-if="isDomainReady && isDomainVerificationReady">
                    <!-- <p class="text-overline mb-0">Verification code</p>
                    <template v-if="!verificationCode">
                    <p class="mb-0 pb-0">
                        Getting code...
                    </p>
                    </template>
                    <template v-if="verificationCode">
                    <p class="mb-0 pb-0">
                        {{ verificationCode }}
                    </p>
                    </template> -->

                    <!-- TODO: move this to a special-purpose page for checking status of a domain and presenting user with the necessary steps -- so the query parameter for that page will be a domain NAME, not an id, and then we'll look to see if it's in the account or not; if it's not in the account, add it; if it's in the account, check if it's verified; if it's not verified, start verification; if it's verified, show the status -->
                    <!--
                    <template v-if="isCreateDomainRequired">
                    <p class="mb-0 pb-0">
                        The domain has not yet been added to your account.
                    </p>
                    <p class="mb-0 pb-0">
                        <v-btn color="green" class="white--text" @click="createDomain">Add it now</v-btn>
                    </p>
                    </template>
                    -->

                    <template v-if="isDomainVerified">
                        <p class="text-overline mb-0">Domain status</p>
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                            Verified.
                        </p>
                        <p class="mb-0 pb-0 mt-4">
                            <!-- <router-link :to="{ name: 'account-edit-domain', params: { accountId: this.$route.params.accountId, domainId: this.domain.id } }">Continue</router-link> -->
                            <v-btn color="teal white--text" :to="{ name: 'account-edit-domain', params: { accountId: this.$route.params.accountId, domainId: this.domain.id } }">Continue</v-btn>
                        </p>
                    </template>

                    <template v-if="!isDomainVerified">
                        <DomainVerificationCard :domain="domain" :verification="verification" @verified="onDomainVerificationComplete"/>
                    </template>
                </template>
                <template v-if="!isDomainReady || !isDomainVerificationReady">
                    <p class="mb-0 pb-0">
                    Loading...
                    </p>
                </template>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import DomainVerificationCard from '@/components/DomainVerificationCard.vue';

export default {
    components: {
        DomainVerificationCard,
    },
    data: () => ({
        account: null,
        domain: null,
        verification: null,
        isDomainReady: false,
        isDomainVerificationReady: false,
        error: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        isViewReady() {
            return this.account !== null;
        },
        isDomainVerified() {
            return this.domain !== null && this.domain.status === 'verified';
        },
        isCreateDomainRequired() {
            return this.domain === null;
        },
    },
    watch: {
        isDomainVerified(newValue) {
            if (newValue) {
                // automatically redirect user to domain edit
                this.$router.replace({ name: 'account-edit-domain', params: { accountId: this.$route.params.accountId, domainId: this.domain.id } });
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ id: this.$route.params.domainId });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                }
            } catch (err) {
                if (err.response?.code === 404) {
                    console.log('domain not found');
                } else {
                    console.error('failed to load domain request', err);
                }
                this.domain = null;
                // this.error = true;
                // this.loadDomainVerification();
            } finally {
                this.$store.commit('loading', { loadDomain: false });
                this.isDomainReady = true;
            }
        },
        async loadDomainVerification() {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { loadDomainVerification: true });
                const response = await this.$client.account(this.$route.params.accountId).domainVerification.get({ domain_id: this.$route.params.domainId });
                console.log(`loadDomainVerification: response ${JSON.stringify(response)}`);
                if (response) {
                    console.log(`loadDomainVerification: ${JSON.stringify(response)}`);
                    this.verification = response;
                }
            } catch (err) {
                if (err.response?.code === 404) {
                    console.log('no active domain verification request');
                } else {
                    console.error('failed to load domain verification request', err);
                }
                // this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to get verification code' });
                // this.createVerificationCode();
                this.verification = null;
            } finally {
                this.$store.commit('loading', { loadDomainVerification: false });
                this.isDomainVerificationReady = true;
            }
        },
        async onDomainVerificationComplete() {
            this.loadDomain();
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomain();
        this.loadDomainVerification();
    },
};
</script>
